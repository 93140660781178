import React from "react";
import "./About.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faBath, faCarSide } from "@fortawesome/free-solid-svg-icons";

function About() {
  return (
    <div id="about-section" className="about">
      <div className="about-subsection">
        {/* <h1 className="about-heading">About Mark</h1> */}
        <div className="about-content">
          <div className="about-content-main">
            <div className="bio-stats-container">
              <h1 className="about-heading">About My Company</h1>
              <h2 className="about-sub-heading">
                A Landscaping Company You Can Trust
              </h2>
              <p className="bio">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est
                laborum.sunt in culpa qui officia deserunt mollit anim id est
                laborum.sunt in culpa qui officia deserunt mollit anim id est
                laborum.
              </p>
              {/* <div className="stats-container">
              <ul className="stats-list">
                <li className="stat-item">
                  <h1 className="stat-title">I Come To You</h1>
                  <FontAwesomeIcon
                    icon={faCarSide}
                    size="2xl"
                    style={{ color: "#669f2b" }}
                  />
                </li>
                <li className="stat-item">
                  <h1 className="stat-title">Gentle Handling</h1>
                  <FontAwesomeIcon
                    icon={faBath}
                    size="2xl"
                    style={{ color: "#669f2b" }}
                  />
                </li>
                <li className="stat-item">
                  <h1 className="stat-title">Specialized Care</h1>
                  <FontAwesomeIcon
                    icon={faHeart}
                    size="2xl"
                    style={{ color: "#669f2b" }}
                  />
                </li>
              </ul>
            </div> */}
            </div>
            <img
              className="profile-img"
              alt="mark"
              src="./images/profilepic.jpg"
            />
          </div>
          <div className="sub-images">
            <img
              className="sub-img"
              alt="mark"
              src="./images/background1.jpg"
            />
            <img
              className="sub-img"
              alt="mark"
              src="./images/background2.jpg"
            />
            <img
              className="sub-img"
              alt="mark"
              src="./images/background3.jpg"
            />
            <img
              className="sub-img"
              alt="mark"
              src="./images/background4.jpg"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
