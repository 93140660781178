import React, { useState } from "react";
import "./Contact.css";
import Button from "./Button";
import { InlineWidget } from "react-calendly";
import emailjs from "@emailjs/browser";
import { PopupWidget } from "react-calendly";

function ContactForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const submittedMessage = (
    <h3 className="submitted-message">Thanks! Your form has been submitted.</h3>
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = "service_hqqwafb";
    const templateId = "template_zefthub";
    const publicKey = "dxAd7pAs5UMk8oX_l";

    const templateParams = {
      fromName: name,
      fromEmail: email,
      phone: phone,
      message: message,
    };

    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log("Email sent successfully!", response);
        setIsSubmitted(true);
        setTimeout(() => setIsSubmitted(false), 3000);
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
      })
      .catch((error) => {
        console.log("Error sending email", error);
      });
  };

  return (
    <div>
      <div className="contact-section">
        <div className="contact-container">
          <div className="contact-image-container">
            <img
              src="./images/contact.jpg"
              alt="phone"
              className="contact-img"
            />
          </div>
          <div id="contact-form" className="form-body">
            <form className="form_container" onSubmit={handleSubmit}>
              <h2 className="form_header">Get In Touch</h2>

              <div className="name_container">
                <label className="name-label">
                  Name
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="#669f2b"
                    width={18}
                    height={18}
                  >
                    <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
                  </svg>
                </label>
                <input
                  name="name"
                  placeholder="Enter your name"
                  className="name_input"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>

              <div className="phone_container">
                <label className="phone">
                  Phone
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="#669f2b"
                    width={18}
                    height={18}
                  >
                    <path d="M8 16.25a.75.75 0 01.75-.75h2.5a.75.75 0 010 1.5h-2.5a.75.75 0 01-.75-.75z" />
                    <path
                      fillRule="evenodd"
                      d="M4 4a3 3 0 013-3h6a3 3 0 013 3v12a3 3 0 01-3 3H7a3 3 0 01-3-3V4zm4-1.5v.75c0 .414.336.75.75.75h2.5a.75.75 0 00.75-.75V2.5h1A1.5 1.5 0 0114.5 4v12a1.5 1.5 0 01-1.5 1.5H7A1.5 1.5 0 015.5 16V4A1.5 1.5 0 017 2.5h1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </label>
                <input
                  name="phone"
                  placeholder="Enter your Phone"
                  value={phone}
                  className="phone_input"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>

              <div className="email_container">
                <label className="email">
                  Email
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="#669f2b"
                    width={18}
                    height={18}
                  >
                    <path d="M3 4a2 2 0 00-2 2v1.161l8.441 4.221a1.25 1.25 0 001.118 0L19 7.162V6a2 2 0 00-2-2H3z" />
                    <path d="M19 8.839l-7.77 3.885a2.75 2.75 0 01-2.46 0L1 8.839V14a2 2 0 002 2h14a2 2 0 002-2V8.839z" />
                  </svg>
                </label>
                <input
                  name="email"
                  placeholder="Your email"
                  className="email_input"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              <div className="message_container">
                <label className="message">
                  Message
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="#669f2b"
                    width={18}
                    height={18}
                  >
                    <path
                      fillRule="evenodd"
                      d="M3.43 2.524A41.29 41.29 0 0110 2c2.236 0 4.43.18 6.57.524 1.437.231 2.43 1.49 2.43 2.902v5.148c0 1.413-.993 2.67-2.43 2.902a41.202 41.202 0 01-5.183.501.78.78 0 00-.528.224l-3.579 3.58A.75.75 0 016 17.25v-3.443a41.033 41.033 0 01-2.57-.33C1.993 13.244 1 11.986 1 10.573V5.426c0-1.413.993-2.67 2.43-2.902z"
                      clipRule="evenodd"
                    />
                  </svg>
                </label>
                <textarea
                  cols="30"
                  rows="6"
                  name="message"
                  placeholder="Enter your message"
                  className="message_input"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                />
              </div>
              <div className="submit-btn">
                <Button
                  className="btn"
                  buttonStyle="btn--primary"
                  buttonSize="btn--full-width"
                >
                  Submit
                </Button>
              </div>
              {isSubmitted && submittedMessage}
            </form>
          </div>
        </div>
      </div>
      <div>
        <div className="App">
          <PopupWidget
            url="https://calendly.com/irishlawnservices/book-yard-service?primary_color=669f2b"
            /*
             * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
             * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
             */
            rootElement={document.getElementById("root")}
            text="Click here to schedule!"
            textColor="#ffffff"
            color="#00a2ff"
            border-radius="5px"
          />
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
