import React, { useState } from "react";
import Service from "./Service";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/bundle";
import "./Services.css";
import servicesData from "../servicesData";
import Button from "./Button";
import handleClickContact from "../utils/GoToForm";
import { PopupButton } from "react-calendly";

function Services() {
  // function handleClick() {
  //   const targetElement = document.getElementById('project-section');
  //   targetElement.scrollIntoView({ behavior: 'smooth' });
  // }

  const [slides, setSlides] = useState(3);

  function handleResize() {
    let screenWidth = window.innerWidth;
    console.log(`Screen width: ${screenWidth}px`);

    if (screenWidth <= 500) {
      setSlides(1);
    } else if (screenWidth > 500 && screenWidth <= 900) {
      setSlides(2);
    } else {
      setSlides(3);
    }
  }
  window.addEventListener("resize", handleResize);

  return (
    <div>
      <div id="project-section">
        <div className="services-header-container">
          <h1 className="services-title">Services</h1>
        </div>
        <div id="card-section" className="cards-section">
          <div className="cards">
            <div>
              <Swiper
                modules={[Pagination, Navigation]}
                slidesPerView={slides}
                spaceBetween={30}
                loop={true}
                // pagination={{ clickable: true }}
                navigation={true}
                className="slide_container"
              >
                {servicesData.map((service) => {
                  return (
                    <SwiperSlide className="slide_content">
                      <Service
                        src={service.src}
                        title={service.title}
                        description={service.description}
                        price={service.price}
                        className="cards__items"
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
        <div className="booking-button-container">
          <PopupButton
            url="https://calendly.com/irishlawnservices/book-yard-service?primary_color=669f2b"
            rootElement={document.getElementById("root")}
            text="Book Now"
            className="btn btn--primary btn--large"
          />
        </div>
      </div>
    </div>
  );
}

export default Services;
