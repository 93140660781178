import React from "react";
import Button from "./Button";
import handleClickContact from "../utils/GoToForm";

function Service(props) {
  return (
    <div className="card_wrapper">
      <div className="card">
        <div className="image_content">
          <span className="overlay"></span>
          <div className="card_image">
            <img src={props.src} alt="Project" className="card_img" />
          </div>
        </div>

        <div className="card_content">
          <h2 className="name">{props.title}</h2>
          <p className="description">{props.description}</p>
          {/* <h3 className='price'>{props.price}</h3>
                    <Button className='btns' buttonStyle='btn--primary' buttonSize="btn--large" onClick={handleClickContact} >Book Now</Button> */}
        </div>
      </div>
    </div>
  );
}

export default Service;
