const servicesData = [
  {
    title: "Line Trimming",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut",
    src: "./images/services/line-trimming.jpeg",
  },
  {
    title: "Soil Analysis & pH Testing",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut",
    src: "./images/services/soil-analysis.jpeg",
  },
  {
    title: "Lawn Health Care",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut",
    src: "./images/services/lawn-health.jpeg",
  },
  {
    title: "Plant Health Care",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut",
    src: "./images/services/plant-health.jpeg",
  },
  {
    title: "Hedge Trimming",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut",
    src: "./images/services/hedge-trimming.jpeg",
  },
  {
    title: "Mulch Installation ",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut",
    src: "./images/services/mulch.jpeg",
  },
  {
    title: "Soil Installation",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut",
    src: "./images/services/soil.jpeg",
  },
  {
    title: "Tree Pruning",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut",
    src: "./images/services/tree-pruning.jpeg",
  },
  {
    title: "Pressure Washing",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut",
    src: "./images/services/pressure-washing.jpeg",
  },
  {
    title: "Leaf Management",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut",
    src: "./images/services/leaf-management.jpeg",
  },
];

export default servicesData;
