// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./rootStyles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn {
  padding: 8px 20px;
  border-radius: 25px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: var(--primary-color);
  color: white;
  font-weight: 600;
}

.btn--outline {
  background-color: white;
  color: black;
  padding: 8px 20px;
  transition: all 0.3s ease-out;
  font-weight: 600;
}

.btn-medium {
  padding: 8px 20px;
  font-size: 1.8rem;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--full-width {
  padding: 12px 26px;
  font-size: 20px;
  width: 100%;
}

.btn--medium:hover {
  transform: scale(1.05);
  transition: all 0.3s ease-out;
}

.btn--large:hover {
  transform: scale(1.05);
  transition: all 0.3s ease-out;
}

.btn--full-width:hover {
  transform: scale(1.05);
  transition: all 0.3s ease-out;
}
`, "",{"version":3,"sources":["webpack://./src/components/Button.css"],"names":[],"mappings":"AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,aAAa;EACb,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,sCAAsC;EACtC,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,iBAAiB;EACjB,6BAA6B;EAC7B,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,sBAAsB;EACtB,6BAA6B;AAC/B;;AAEA;EACE,sBAAsB;EACtB,6BAA6B;AAC/B;;AAEA;EACE,sBAAsB;EACtB,6BAA6B;AAC/B","sourcesContent":["@import \"rootStyles\";\n\n.btn {\n  padding: 8px 20px;\n  border-radius: 25px;\n  outline: none;\n  border: none;\n  cursor: pointer;\n}\n\n.btn--primary {\n  background-color: var(--primary-color);\n  color: white;\n  font-weight: 600;\n}\n\n.btn--outline {\n  background-color: white;\n  color: black;\n  padding: 8px 20px;\n  transition: all 0.3s ease-out;\n  font-weight: 600;\n}\n\n.btn-medium {\n  padding: 8px 20px;\n  font-size: 1.8rem;\n}\n\n.btn--large {\n  padding: 12px 26px;\n  font-size: 20px;\n}\n\n.btn--full-width {\n  padding: 12px 26px;\n  font-size: 20px;\n  width: 100%;\n}\n\n.btn--medium:hover {\n  transform: scale(1.05);\n  transition: all 0.3s ease-out;\n}\n\n.btn--large:hover {\n  transform: scale(1.05);\n  transition: all 0.3s ease-out;\n}\n\n.btn--full-width:hover {\n  transform: scale(1.05);\n  transition: all 0.3s ease-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
