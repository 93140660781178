import React from "react";
import "./Navbar.css";
import handleClickContact from "../utils/GoToForm";
import handleClickAbout from "../utils/GoToAbout";
import handleClickServices from "../utils/GoToServices";

function Navbar() {
  return (
    <div class="nav-section">
      <nav className="navbar">
        <div className="navbar-container md:sticky">
          <ul className="nav-menu active">
            <li>
              <button className="btn nav-item" onClick={handleClickAbout}>
                About
              </button>
            </li>
            <li>
              <button className="btn nav-item" onClick={handleClickServices}>
                Services
              </button>
            </li>
            <li>
              <button className="btn nav-item" onClick={handleClickContact}>
                Contact
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
