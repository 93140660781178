import React from "react";
import Button from "./Button";
import "./Hero.css";
import handleClickContact from "../utils/GoToForm";
import handleClickAbout from "../utils/GoToAbout";
import { PopupButton } from "react-calendly";
import Navbar from "./Navbar";

function Hero() {
  return (
    <div>
      <div className="hero-section">
        {/* <img src='./images/background.jpg' alt='dog being groomed' className='background-img'/> */}
        <Navbar />
        <div className="hero">
          <div className="hero-container">
            <div className="item1">
              <h1 className="title">Irish Lawn Services</h1>
              <p>Vancouver based premium lawncare</p>
              <div className="hero-btns">
                <Button
                  onClick={handleClickAbout}
                  className="btn"
                  buttonStyle="btn--outline"
                  buttonSize="btn--large"
                >
                  Find Out More
                </Button>
              </div>
              <div className="hero-btns">
                <PopupButton
                  url="https://calendly.com/irishlawnservices/book-yard-service?primary_color=669f2b"
                  rootElement={document.getElementById("root")}
                  text="Book Now"
                  className="btn btn--primary btn--large"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Hero;
