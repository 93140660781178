import "./App.css";
import About from "./components/About";
import ContactForm from "./components/Contact";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import Services from "./components/Services";

function App() {
  return (
    <div className="App">
      {/* <Navbar /> */}
      <Hero />
      <About />
      <Services />
      <ContactForm />
    </div>
  );
}

export default App;
