// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary-color: #669f2b;
  --secondary-color: black;
  --background-color: white;
  --secondary-background-color: black;
  --primary-text-color: white;
  --secondary-text-color: black;
  --font: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans",
    Arial, sans-serif;
  --header-font-size: 96px;
  --secondary-font-size: 60px;
  --text-font-medium: 20px;
  --text-font-small: 18px;
  --bold: 400;
  --paragraph-gap: 2;
  --desktop-width: 1100px;
  --tablet-width: 680px;
  --phone-width: 300px;
  --desktop-margin: 3rem;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/components/rootStyles.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,wBAAwB;EACxB,yBAAyB;EACzB,mCAAmC;EACnC,2BAA2B;EAC3B,6BAA6B;EAC7B;qBACmB;EACnB,wBAAwB;EACxB,2BAA2B;EAC3B,wBAAwB;EACxB,uBAAuB;EACvB,WAAW;EACX,kBAAkB;EAClB,uBAAuB;EACvB,qBAAqB;EACrB,oBAAoB;EACpB,sBAAsB;AACxB;;AAEA;;EAEE,SAAS;EACT,UAAU;EACV,WAAW;EACX,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;;;EAGE,mBAAmB;AACrB","sourcesContent":[":root {\n  --primary-color: #669f2b;\n  --secondary-color: black;\n  --background-color: white;\n  --secondary-background-color: black;\n  --primary-text-color: white;\n  --secondary-text-color: black;\n  --font: \"Trebuchet MS\", \"Lucida Sans Unicode\", \"Lucida Grande\", \"Lucida Sans\",\n    Arial, sans-serif;\n  --header-font-size: 96px;\n  --secondary-font-size: 60px;\n  --text-font-medium: 20px;\n  --text-font-small: 18px;\n  --bold: 400;\n  --paragraph-gap: 2;\n  --desktop-width: 1100px;\n  --tablet-width: 680px;\n  --phone-width: 300px;\n  --desktop-margin: 3rem;\n}\n\nhtml,\nbody {\n  margin: 0;\n  padding: 0;\n  width: 100%;\n  overflow-x: hidden;\n  box-sizing: border-box;\n}\n\n*,\n*::before,\n*::after {\n  box-sizing: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
